import { cn } from "@basaltbytes/ui/index";
import { useLocation } from "@remix-run/react";

export type Announcement = {
  title: string;
  description?: {
    text: string;
    html: string;
  };
  pathStartsWith?: string;
};
export type StickyBannerProps = {
  announcements: Array<Announcement>;
  className?: string;
  onClose?: () => void;
};

export const StickyBanner = ({
  announcements,
  className,
}: StickyBannerProps) => {
  const { pathname } = useLocation();
  let validAnnouncements = announcements.filter(
    ({ pathStartsWith }) =>
      !pathStartsWith || pathname.startsWith(pathStartsWith),
  );
  if (validAnnouncements.length === 0) return null;
  return (
    <div
      className={cn(
        "sticky left-0 right-0 top-0 z-50 border-t-4 border-cyan-500 bg-cyan-100 px-4 py-3 text-cyan-900 shadow-md",
        className,
      )}
      role="alert"
    >
      <div className="flex">
        <div className="py-1">
          <svg
            className="mr-4 h-6 w-6 fill-current text-cyan-500"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
          </svg>
        </div>
        <div className="flex flex-col gap-2">
          {validAnnouncements.map(({ title, description }, index) => (
            <div key={index}>
              <h5 className="mb-1 text-sm font-bold">{title}</h5>
              {description?.html ? (
                <div
                  className="prose text-xs leading-normal"
                  dangerouslySetInnerHTML={{ __html: description.html }}
                />
              ) : description?.text ? (
                <p className="text-xs">{description.text}</p>
              ) : null}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
